import {
  Drawer,
  Form,
  Button,
  DatePicker,
  Select,
  Radio,
  Space,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import _ from "lodash";
import { SLOT_OPTIONS, STATUS_OPTIONS } from "constants/venueCalendarEnums";

export default function AddEventForm(props) {
  const [form] = Form.useForm();
  const [isAdding, setIsAdding] = useState(false);
  const [isDateRange, setIsDateRange] = useState(true); 
  const [selectedDates, setSelectedDates] = useState([]);
  const [isDateSelectionType, setIsDateSelectionType] = useState(true); 

  useEffect(() => {
    if (!_.isEmpty(props.selectInfo)) {
      form.setFieldsValue({
        date: [dayjs(props.selectInfo.start), dayjs(props.selectInfo.end)],
      });
    }
  }, [props.selectInfo]);

  const onClose = () => {
    props.resetSelectInfo();
    props.setAddDrawerVisible(false);
  };

  const onFinish = () => {
    form.validateFields().then(() => {
      setIsAdding(true);
      let value = form.getFieldsValue();
      let payload = {};
  
      if (isDateRange) {
        payload.start_date = dayjs(value.date?.[0]).startOf("day").toISOString();
        payload.end_date = dayjs(value.date?.[1]).startOf("day").toISOString();
      } else {
        payload.specific_dates = value.date?.map((date) =>
          dayjs(date).startOf("day").toISOString()
        );
      }
  
      payload.time_slot = value.slot;
      payload.time_slot_status = value.status;
      payload.venuespaces = value.venuespaces
        ? value.venuespaces
        : [props.venue._id];
  
      const submitEvent = isDateRange ? props.handleEventAdd : props.handleSpecificAdd;
  
      submitEvent(payload)
        .then(() => {
          setIsAdding(false);
          onClose();
        })
        .catch((error) => {
          setIsAdding(false);
        });
    }).catch((error) => {
      setIsAdding(false);
    });
  };
  
  
  const dateFieldValidator = () => {
    let selectedDates = form.getFieldValue("date");

    if (isDateRange) {
      if (selectedDates && selectedDates.length === 2) {
        let diff = dayjs(selectedDates[1])
          .startOf("day")
          .diff(dayjs(selectedDates[0]).startOf("day"), "days");
        if (diff >= 1) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            "Start date and End date should have a difference of at least 1 day"
          );
        }
      } else {
        return Promise.reject("Please select both Start and End Dates");
      }
    } else {
      if (selectedDates && selectedDates.length >= 1) {
        setSelectedDates(selectedDates);
        return Promise.resolve();
      } else {
        return Promise.reject("Please select at least one date");
      }
    }
  };

  const venueSpaceValidator = () => {
    let venueSpaces = form.getFieldValue("venuespaces");
    if (props.venueSpacesList.length <= 0) {
      return Promise.resolve();
    } else {
      if (venueSpaces && !_.isEmpty(venueSpaces)) {
        return Promise.resolve();
      } else return Promise.reject("VenueSpace Cannot be empty");
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    if (isDateRange) {
      form.setFieldsValue({
        date: dates,
      });
    } else {
      setSelectedDates(dates); 
    }
  };

  return (
    <Drawer
      title="Add Event"
      placement="right"
      onClose={onClose}
      open={props.open}
      width={400}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" onClick={onClose} disabled={isAdding}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isAdding}
            onClick={onFinish}
          >
            Ok
          </Button>
        </div>
      }
    >
      <Spin spinning={isAdding}>
        <Form form={form} name="eventForm" layout="vertical">
          <Form.Item
            name="dateSelection"
            label={<b>Date Selection</b>}
            rules={[{ required: true, message: "Please select a date type" }]}
          >
            <Radio.Group
              value={isDateSelectionType} 
              onChange={(e) => {
                setIsDateSelectionType(e.target.value); 
                setIsDateRange(e.target.value); 
                form.setFieldsValue({ date: [] });
                setSelectedDates([]); 
              }}
            >
              <Radio value={true}>Range</Radio>
              <Radio value={false}>Multi</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="date"
            label={<b>{isDateRange ? "From-To" : "Select Dates"}</b>}
            rules={[
              {
                validator: dateFieldValidator,
              },
            ]}
          >
            {isDateRange ? (
              <DatePicker.RangePicker
                format="DD/MM/YYYY"
                onChange={handleDateChange}
                value={form.getFieldValue("date")}
                style={{ width: "100%" }}
              />
            ) : (
              <DatePicker
                format="DD/MM/YYYY"
                onChange={handleDateChange}
                value={selectedDates} // Bind to selectedDates directly
                style={{ width: "100%" }}
                multiple
              />
            )}
          </Form.Item>

          {selectedDates.length > 0 && (
            <Form.Item
              style={{ marginTop: "5px", width: "100%" }}
              label={<b>Selected Dates:</b>}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {selectedDates.map((date, index) => (
                  <div key={index} style={{ margin: "2px", whiteSpace: "nowrap" }}>
                    {dayjs(date).format("DD MMM YYYY")}
                    {index !== selectedDates.length - 1 && " ,"}
                  </div>
                ))}
              </div>
            </Form.Item>
          )}

          <Form.Item
            name="slot"
            label={<b>Slot</b>}
            rules={[{ required: true, message: "Slot needed to be selected" }]}
          >
            <Select
              size="large"
              style={{ width: "140px" }}
              mode="multiple"
              dropdownStyle={{ zIndex: "2500" }}
            >
              {SLOT_OPTIONS.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="venuespaces"
            label={<b>Venue spaces</b>}
            rules={[{ validator: venueSpaceValidator }]}
          >
            <Select
              showSearch
              dropdownStyle={{ zIndex: "2500" }}
              style={{ width: "150px" }}
              mode="multiple"
              disabled={props.venueSpacesList.length <= 0}
            >
              {props.venueSpacesList.map((item) => (
                <Select.Option value={item._id} key={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="status"
            rules={[{ required: true, message: "Status Cannot be empty" }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {STATUS_OPTIONS.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

