import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./AvailabilityCalender.css";
import dayjs from "dayjs";


import AddEventForm from "./components/AddEventForm";
import _ from "lodash";
import UpdateEventForm from "./components/AvailabilityEditForm";
import AvailablityCalendarHeader from "./components/AvailablityCalendarHeader";

import { Select, Spin } from "antd";
import { getViewType,eventColorBasedOnStatus } from "util/venueCalendar";


import callVenueApi from "util/venueApiCaller";
import callManagerApi from "util/managerAPICaller";
import { VENUE_SPACE_COLOR_OPTIONS } from "constants/venueCalendarEnums";
import { useDispatch, useSelector } from "react-redux";
import { getActiveVenue } from "modules/App/AppReducer";
import { userNotify } from "components/Toasts/ToastAdd";
import SpinnerLoader from "components/Loader/Spinner";
import ScreenLoader from "components/Loader/ScreenLoader";

export default function AvailablityCalendar(props) {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [eventsCount, setEventsCount] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [venueSpacesList, setVenueSpacesList] = useState([]);
  const [isAddDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectInfo, setSelectInfo] = useState({});
  const [eventObjMap, setEventObjMap] = useState({});
  const [isUpdateDrawerVisible, setUpdateDrawerVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isLoading,setIsLoading]=useState(false)
  const [ appliedFilters ,setAppliedFilters] = useState({})
  const [currentViewStartDate,setCurrentViewStartDate]=useState(dayjs().toISOString())
  const [currentViewEndDate,setCurrentViewEndDate]=useState(dayjs().toISOString())
  const dispatch = useDispatch()
  const venue =useSelector((state)=>getActiveVenue(state))
  const [venueLoading,setVenueLoading]=useState(true)
  const [seletedView,setSelectedView]=useState()
  
  

  const calendarRef = React.useRef();

  const makeEventVenueSpaceObjectMap = (eventsList, venueSpaceList) => {
    let modal = {};
    if (
      eventsList &&
      venueSpaceList &&
      eventsList.length > 0 &&
      venueSpaceList.length > 0
    ) {
      for (let event of eventsList) {
        for (let venueSpace of venueSpaceList) {
          if (event.venuespace_id == venueSpace._id) {
            modal[event._id] = {};
            modal[event._id].venue = venue.name;
            modal[event._id].venueSpaceId = venueSpace._id;
            modal[event._id].venueSpaceName = venueSpace.name;
            modal[event._id].venueSpaceColor = venueSpace.color;
            modal[event._id].slot = event.time_slot;
            modal[event._id].status = event.time_slot_status;
          }
        }
      }
    } else {
      for (let event of eventsList) {
        modal[event._id] = {};
        modal[event._id].venueSpaceId = venue._id;
        modal[event._id].venueSpaceName = venue.name;
        modal[event._id].slot = event.time_slot;
        modal[event._id].status = event.time_slot_status;
      }
    }
    return modal;
  };


  useEffect(()=>{
    if(venue && !_.isEmpty(venue)){
      setVenueLoading(false)
    }
  },[venue])



  const getVenueSpaceName = (eventId, eventObjMap) => {
    if (eventObjMap && !_.isEmpty(eventObjMap)) {
      return eventObjMap[eventId]?.venueSpaceName
        ? eventObjMap[eventId]?.venueSpaceName
        : venue.name;
    } else return venue.name;
  };

  const refreshList = async (filters = {},newFiltersApplied) => {
    let modal = {};
    if(!newFiltersApplied){
      filters={...filters,...appliedFilters}
    }
    // debugger

    try {
    setIsLoading(true)
      modal.eventsList = await getAvailablityEvents(venue._id, filters);
      
      let venueSpaces = await fetchVenueSpaces();
      let venueSpaceList = [];
      venueSpaces.forEach((item, index) => {
        item.color = VENUE_SPACE_COLOR_OPTIONS[index];
        venueSpaceList.push(item);
      });
      modal.venueSpaceList = venueSpaceList;
      modal.eventObjMap = makeEventVenueSpaceObjectMap(
        modal.eventsList,
        modal.venueSpaceList
      );
      if (modal.eventsList && modal.venueSpaceList) {
        modal.transformedList = transformAvailablityList(
          modal.eventsList,
          modal.eventObjMap
        );
      }
      setEventsList(modal.eventsList);
      setVenueSpacesList(modal.venueSpaceList);
      if (modal.eventObjMap) {
        setEventObjMap(modal.eventObjMap);
      }
      setCurrentEvents(modal.transformedList);
      setEventsCount(modal.eventsList.length);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e);
    }
  };

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const resetSelectInfo = () => {
    setSelectInfo({});
  };

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
   let type= getViewType(selectInfo.view.type)
    if(type=="month"){
      setSelectInfo(selectInfo);
      setAddDrawerVisible(true);
    }
    else{
      calendarApi.unselect()
      showMessage("This Feature is currently available in month view only",'warning')
    }
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event.toPlainObject());
    setUpdateDrawerVisible(true);
  };

  function renderDayCell(dayCellContent) {
    return <div>{dayCellContent.dayNumberText}</div>;
  }

  const fetchVenueSpaces = async () => {
    try {
      let res = await callVenueApi(
        `api/v2/venuespaces/venue/${venue._id}/list`,
        "get"
      );
      if (res.status === "Success") {
        return res.data.venuespace;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  };



  const transformAvailablityList = (venuecalenderList, eventObjMap) => {
    return venuecalenderList.map((item) => {
      return {
        id: item._id,
        title: _.truncate(getVenueSpaceName(item._id, eventObjMap),"20"),
        start: dayjs(item.start_date).toISOString(),
        end: dayjs(item.end_date).toISOString(),  
        color: eventColorBasedOnStatus(item.time_slot_status),
        textColor: "white",
      };
    });
  };

  const getAvailablityEvents = async (venue_id, filters = {}) => {
    if (venue_id) {
      try {
        let res = await callVenueApi(
          `v1/venue-calender/venue/${venue_id}/event-list`,
          "Post",
          { filters: filters }
        );
        if ((res.status = "Success")) {
          return res.data.venuecalenderList;
        } else {
          return [];
        }
      } catch (e) {
        showMessage("error in fetching list", "error", 3000);
        console.log(e);
      }
    }
  };

  const showMessage = (msg, type) => {
   userNotify(msg, type);
  };



  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const getVenueSpaceColor = (eventid) => {
    if (venueSpacesList.length <= 0) {
      return VENUE_SPACE_COLOR_OPTIONS[0];
    } else {
      return eventObjMap?.[eventid]?.venueSpaceColor
        ? eventObjMap?.[eventid]?.venueSpaceColor
        : VENUE_SPACE_COLOR_OPTIONS[VENUE_SPACE_COLOR_OPTIONS.length - 1];
    }
  };
  const renderEventContent = (eventInfo) => {
    let eventId = eventInfo.event.toPlainObject().id
    return (
      <div
        className="event-main"
      >
        <div
          style={{
            width: "10%",
            backgroundColor: "#FFEEBF",
            display:"flex",
            justifyContent:"center"
          }}
        ><b style={{color:"#1A1A1A" }}>{eventObjMap?.[eventId]?.slot[0].toUpperCase()}</b></div>
        <i>{eventInfo.event.title}</i>
      </div>
    );
  };


  const handleEventBulkAdd = async (payload) => {
    try {
      let res = await callVenueApi(
        `v1/venue-calender/venue/${venue._id}/bulk-create`,
        "post",
        payload
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showMessage("success", "success", 3000);
        props.refresh()
      }
      else{
        showMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSpecificAdd = async (payload) => {
    try {
      let res = await callVenueApi(
        `v1/venue-calender/venue/${venue._id}/specific-bulk-create`,
        "post",
        payload
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showMessage("success", "success", 3000);
        
      }
      else{
        showMessage(res?.message ? res?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleEventEdit = async (eventid,payload) => {
    try {
      let body ={venuecalender:payload}
      let res = await callVenueApi(
        `v1/venue-calender/venue/${venue._id}/venuecalendar/${eventid}/update-event`,
        "post",
        body
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showMessage("success", "success", 3000);
        props.refresh()
      }
      else{
        showMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEventRemove = async (eventId) => {
    try {
      let res = await callVenueApi(
        `v1/venue-calender/${eventId}/remove`,
        "post"
      );
      if (res.status == "Success") {
        await refreshList({
          start_date: dayjs(currentViewStartDate).startOf("month").toISOString(),
          end_date: dayjs(currentViewEndDate).endOf("month").toISOString(),
        });
        showMessage("success", "success", 3000);
        props.refresh()
      }
      else{
        showMessage(res?.data?.message ? res?.data?.message : "Error" , 'error' ,3000 )
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addToFilters=(obj)=>{
   setAppliedFilters({...appliedFilters,...obj})
  }

  const cellData = (cell) => {
    // if(dayjs(convertToDayString(cell.date)).isSame(dayjs(convertToDayString(dayjs())))){
    //   let a = cell.el
    //   a.children[0].style.backgroundColor = "red"
    //   console.log(a.children[0].childNodes[2], "CHILDREN")
    // } //=> to be done later
  };

  const viewChange = () => {};

  const dateSet = (dateInfo) => {
    setCurrentViewStartDate(dateInfo.start)
    setCurrentViewEndDate(dateInfo.end)
   
    let type = getViewType(dateInfo.view.type);
    setSelectedView(type)
    refreshList({
      start_date: dayjs(dateInfo.start).startOf(type).toISOString(),
      end_date: dayjs(dateInfo.end).endOf(type).toISOString(),
    });
  };

  const customButtons = {
    addEventButton: {
      text: " + ",
      click: () => {
        setAddDrawerVisible(true);
      },
    },
  };

  return (

    venueLoading ? <>
    <ScreenLoader/>
    </>
    :
    <div className="calendar-app">
      <div className="calendar-main">
        <AvailablityCalendarHeader
          venueSpaceList={venueSpacesList}
          venueId={venue.id}
          refresh={refreshList}
          addToFilters={addToFilters}
          currentViewStartDate={currentViewStartDate}
          currentViewEndDate={currentViewEndDate}
        />
       <Spin spinning={isLoading}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          customButtons={customButtons}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "addEventButton dayGridMonth,timeGridWeek,timeGridDay",
          }}
          viewDidMount={viewChange}
          event
          initialView="dayGridMonth"
          ref={calendarRef}
          nowIndicatorContent
          eventDisplay="block"
          // editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          dayCellContent={renderDayCell}
          datesSet={dateSet}
          dayCellDidMount={cellData}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          events={currentEvents}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          //  you can update a remote database when these fire:
          // eventAdd={handleEventBulkAdd}
          eventChange={function () {}}
          eventRemove={function () {}}
        />
        </Spin>
      </div>
      {isAddDrawerVisible && (
        <AddEventForm
          open={isAddDrawerVisible}
          resetSelectInfo={resetSelectInfo}
          venue={venue}
          handleEventAdd={handleEventBulkAdd}
          venueSpacesList={venueSpacesList}
          selectInfo={selectInfo}
          setAddDrawerVisible={setAddDrawerVisible}
          handleSpecificAdd={handleSpecificAdd}
        />
      )}
      {isUpdateDrawerVisible && (
        <UpdateEventForm
        userPermissions={props.userPermissions}
          update={handleEventEdit}
          remove={handleEventRemove}
          eventObjMap={eventObjMap}
          open={isUpdateDrawerVisible}
          venue={venue}
          venueSpacesList={venueSpacesList}
          event={selectedEvent}
          setUpdateDrawerVisible={setUpdateDrawerVisible}
        />
      )}
    </div>
  );
}
