import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from "react-bootstrap";
import {
    getActiveVenueId,
    getLeadManageParentPath,
    getLeadManageStaticContent, getPartnerAccount,
    getSessionUserId,
    getUserAgent,
} from '../../../App/AppReducer';
import {
    addNewSubPackage, deleteSubPackage,
    fetchLeadDetails,
    fetchPrimaryAssets,
    fetchSubPackageList,
    showFullViewTabVisibleState,
    updateSubPackage
} from '../../LeadManageActions';
import {
    getAddingSubPackageStatus,
    getEditingSubPackageStatus,
    getFetchingSubpackageListStatus,
    getFullViewTabVisiblestate,
    getLeadDetails,
    getLeadLogId,
    getMenuList, getPackages,
    getSelectedLeadId,
    getSubPackageCount,
    getSubPackagePageNum,
    getSubPackagePageSize,
    getSubPackages
} from '../../LeadManageReducer';
import AddPackage from "./components/AddPackage";
import NavBar from "../../components/NavBar";
import {userNotify} from '../../../../components/Toasts/ToastAdd';
import BasicLoader from '../../../../components/Loader/BasicLoader';
import Breadcrumb from '../../components/Beadcrumb';
import {withRoute} from 'util/withRoute';
import _ from 'lodash';
import MobileNavigationBar from "../../components/MobileNavigationBar";
import CustomCard, {CardBody, CardFooter, CardHeader} from "../../../../components/CardComponent/CustomCard";
import {Drawer, Modal} from "antd";
import EditPackage from "./components/EditPackage";
import {Helmet} from "react-helmet";
import SwitchControl from "../../../../components/PermissionLocks/SwitchControl";

const MODULE_NAME = 'event';

class PackageListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuList: [],
            packageList: [],
            addPackage: {},
            editPackage: {},
            isShowSelectPackage: false,
            isShowAddPackage: false,
            isShowEditPackage: false,
            selectedPackagesToMail: [],
            showPackageDeleteModal: false,
            packageId: ''
        };
    }

    componentDidMount() {
        this.handleDispatch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.leadid !== this.props.leadid ||
            prevProps.lead._id !== this.props.lead._id ||
            prevProps.venueid !== this.props.venueid ||
            prevProps.lead.event !== this.props.lead.event) {
            this.handleDispatch();
        }
    }
    
    handleDispatch() {
        let leadId = this.props?.leadid ? this.props?.leadid : this.props?.lead._id ? this.props?.lead._id : '';
        let eventId = this.props?.lead?.event ? this.props?.lead.event : '';
        let venueId = this.props?.venueid ? this.props?.venueid : '';
        let filters = {};
        filters.pageNum = this.props.pageNum;
        filters.pageSize = this.props.pageSize;
        if(!_.isEqual(leadId)){
        this.props.dispatch(fetchLeadDetails(leadId));
        }
        if(!_.isEqual(venueId)){
        this.props.dispatch(fetchPrimaryAssets(venueId));
        }
        if(eventId && venueId && !_.isEqual(venueId) && !_.isEqual(eventId)){
        this.props.dispatch(fetchSubPackageList(eventId, venueId));
        }
    }
    

    // componentDidUpdate(prevProps) {
    //     let leadId = this.props.leadid ? this.props.leadid : '';
    //     let eventId = this?.props?.lead?.event ? this.props.lead.event : ''
    //     let venueId = this.props.venueid ? this.props.venueid : '';
    //     let filters = {};
    //     filters.pageNum = this.props.pageNum;
    //     filters.pageSize = this.props.pageSize;

    //     if (!_.isEqual(venueId, prevProps.venueid)) {
    //         this.props.dispatch(fetchPrimaryAssets(venueId));
    //     }
    //     if (!_.isEqual(this.props.leadid, prevProps.leadid) || !_.isEqual(this.props.lead._id, prevProps.lead._id)) {
    //         this.props.dispatch(fetchLeadDetails(leadId));
    //     }
    //     if(eventId && venueId && !_.isEqual(venueId, prevProps.venueid) && !_.isEqual(eventId, prevProps.eventId)) {
    //         this.props.dispatch(fetchSubPackageList(eventId, venueId));
    //     }
    // }

    handleOpenAddPackageOverlay = () => {
        this.setState({
            isShowSelectPackage: false,
            isShowAddPackage: true,
            isShowEditPackage: false
        });
    };

    handleCloseAddPackageOverlay = () => {
        this.setState({
            isShowAddPackage: false,
        });
    };

    handleOpenSelectPackageOverlay = () => {
        this.setState({
            isShowSelectPackage: true,
            isShowAddPackage: false,
            isShowEditPackage: false
        });
    };

    handleCloseSelectPackageOverlay = () => {
        this.setState({
            isShowSelectPackage: false
        });
    };

    handleOpenEditPackageOverlay = (editPackage) => {
        this.setState({
            isShowSelectPackage: false,
            isShowAddPackage: false,
            isShowEditPackage: true,
            editPackage: editPackage
        });
    };

    handleCloseEditPackageOverlay = () => {
        this.setState({
            isShowEditPackage: false
        });
    };

    showMessage = (msg, type) => {
        userNotify(msg, type);
    };

    // todo raghav
    handleAddNewSubPackage = (newPackage) => {
        try {
            let agent = this.props.agent ? this.props.agent : {};
            let logId = this.props.logId ? this.props.logId : '';
            let userId = this.props.userId ? this.props.userId : '';
            if(this.props.lead._id && this.props.lead.bundle && this.props.venueid && newPackage) {
                this.props.dispatch(addNewSubPackage(this.props.lead.event ,this.props.lead.bundle, this.props.venueid, newPackage));
            }
        } catch (e) {
            console.log(e)
        }
    };

    handleSelectAddPackage = (addPackage) => {
        this.setState({
            isShowSelectPackage: false,
            isShowAddPackage: true,
            isShowEditPackage: false,
            addPackage: addPackage
        });
    };

    // todo raghav
    handleUpdatePackage = (updatePackage) => {
        let logId = this.props.logId;
        let agent = this.props.agent;
        this.props.dispatch(updateSubPackage(updatePackage._id, updatePackage, logId, agent));
    };

    handleSelectPackageToMail = (id) => {
        let selectedPackagesToMail = this.state.selectedPackagesToMail;
        if (selectedPackagesToMail.indexOf(id) > -1) {
            selectedPackagesToMail = selectedPackagesToMail.filter(item => item !== id);
        } else {
            selectedPackagesToMail.push(id);
        }
        this.setState({selectedPackagesToMail: selectedPackagesToMail});
    };

    handleOpenDeleteModal = (packageId) => {
        this.setState({
            showPackageDeleteModal: true,
            packageId: packageId
        })
    }

    handleCloseDeleteModal = () => {
        this.setState({
            showPackageDeleteModal: false
        })
    }

    handleDeleteConfirm = () => {
        try {
            let packageId = this?.state?.packageId
            this.handleRemoveSubPackage(packageId)
            this.handleCloseDeleteModal()
        } catch (e) {
            console.log(e)
        }

    }

    handleRemoveSubPackage = (packageId) => {
        try {
            let agent = this.props.agent
            let logId = this.props.logId
            this.props.dispatch(deleteSubPackage(packageId, logId, agent))
        } catch (e) {
            console.log(e)
        }
    }

    sendMailToSelectedPackages = () => {
        console.log('Write function to send');
    };

    sendSmsToSelectedPackages = () => {
        console.log('Write function to send');
    };

    handleShowFullView = (val) => {
        this.props.dispatch(showFullViewTabVisibleState(val));
      }

    render() {
        let segment, drawerContent, staticContent = this.props.staticContent ? this.props.staticContent : {};
        let breakpoint = 800;


        if (this.state.isShowAddPackage) {
            drawerContent = <AddPackage
                windowWidth={this.props.windowWidth && this.props.windowWidth}
                breakpoint={breakpoint}
                venueId={this.props.venueid && this.props.venueid}
                showMessage={this.showMessage}
                staticContent={staticContent}
                package={this.state.addPackage}
                eventId={this.props.lead.event}
                leadId={this.props.lead._id}
                isAddingSubPackage={this.props.isAddingSubPackage && this.props.isAddingSubPackage}
                addPackage={this.handleAddNewSubPackage}
                menu={this?.props?.menuList && this.props.menuList}
                packages={this.props.packages ? this.props.packages : []}
                dispatch={this.props.dispatch}
                lead={this.props.lead}
                handleCloseAddPackageOverlay={this.handleCloseAddPackageOverlay}
                
            />
        }
        if (this.state.isShowEditPackage) {
            drawerContent = <EditPackage
                windowWidth={this.props.windowWidth && this.props.windowWidth}
                breakpoint={breakpoint}
                showMessage={this.showMessage}
                staticContent={staticContent}
                package={{...this.state.editPackage}}
                eventId={this.props.lead.event}
                isUpdatingSubPackage={this.props.isUpdatingSubPackage && this.props.isUpdatingSubPackage}
                updatePackage={this.handleUpdatePackage}
                menu={this?.props?.menuList && this.props.menuList}
            />
        }


        segment = <div className="col-sm-12" style={{marginTop: '15px', padding: (this.props.windowWidth < breakpoint) && '0'}}>
            <div style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                marginTop: '15px',
                justifyContent: 'space-between'
            }}>
                <h5>{staticContent.PACKAGE_LIST_PAGE_TITLE}</h5>
                <Modal title='Delete Package' open={this.state.showPackageDeleteModal} onOk={this.handleDeleteConfirm}
                       onCancel={this.handleCloseDeleteModal}>
                    <p>Are you sure you want to delete the package</p>
                </Modal>
                <div>
                    <SwitchControl
                        shouldHavePermissions={['leads:edit']}
                        userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                        yes={
                            <Button onClick={() => this.handleOpenAddPackageOverlay()}
                            style={{width: '200px', backgroundColor: '#2A52BB'}}
                            className="btn btn-primary pull-right">{staticContent.PACKAGE_LIST_PAGE_ADD_PACKAGE_BUTTON_TEXT}</Button>
                        }
                        no={
                            <Button disabled={true} onClick={() => this.handleOpenAddPackageOverlay()}
                            style={{width: '200px', backgroundColor: '#2A52BB'}}
                            className="btn btn-primary pull-right">{staticContent.PACKAGE_LIST_PAGE_ADD_PACKAGE_BUTTON_TEXT}</Button>
                        }
                    />

                </div>

            </div>
            <div style={{position: 'relative', display: 'block', width: '100%', marginTop: '20px'}}>
                {
                    this.props.packageList && this.props.packageList.length > 0 ?
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                            {
                                this.props.packageList.map(item => {
                                    return (
                                        <CustomCard key={item._id} customStyles={{
                                            border: '1px solid var(--b-0-b-0-b-0100, #B0B0B0)',
                                            marginBottom: '30px'
                                        }}>
                                            <CardHeader customStyles={{
                                                padding: '12px 24px',
                                                borderBottom: '1px solid #CDDEFF',
                                                justifyContent: 'space-between',
                                                display: 'flex'
                                            }}>
                                                <span style={{
                                                    color: '#1A1A1A',
                                                    fontSize: '20px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    lineHeight: 'normal',
                                                    margin: 0,
                                                }}>{_.startCase(item.name)}</span>
                                                <SwitchControl
                                                    shouldHavePermissions={['leads:edit']}
                                                    userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                                                    yes={
                                                        <Button onClick={() => this.handleOpenDeleteModal(item._id)} className="btn btn-sm btn-white"><i
                                                            className="fa fa-trash"></i></Button>
                                                    }
                                                    no={
                                                        <Button disabled={true} onClick={() => this.handleOpenDeleteModal(item._id)} className="btn btn-sm btn-white"><i
                                                            className="fa fa-trash"></i></Button>
                                                    }
                                                />
                                            </CardHeader>
                                            <CardBody customStyles={{flexDirection: 'column'}}>
                                                {item.menuItems.length > 0 &&
                                                <p style={{
                                                    color: '#1A1A1A',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                }}>Menu</p> }
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '30px',
                                                    flexWrap: 'wrap',
                                                    padding: '16px 0px'
                                                }}>
                                                    {item.menuItems.map((menuItem) => {
                                                        return (
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '12px'
                                                            }} key={menuItem._id}>
                                                                <div style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    borderRadius: '500px',
                                                                    backgroundColor: '#2A52BB'
                                                                }}></div>
                                                                <span style={{width: '50px', minWidth: 'max-content'}}>{menuItem.name}</span>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </CardBody>
                                            <CardFooter
                                                customStyles={{backgroundColor: '#F2F7FF', padding: '8px 24px'}}>
                                                <span style={{fontSize: '20px'}}>{item.package_price + ' /-'}</span>
                                                <SwitchControl
                                                    shouldHavePermissions={['leads:edit']}
                                                    userPermissions={this.props.partneraccount.permissions ? this.props.partneraccount.permissions : []}
                                                    yes={
                                                        <Button onClick={() => this.handleOpenEditPackageOverlay(item)}
                                                        style={{width: '64px', backgroundColor: '#2A52BB'}}
                                                        className="btn btn-primary pull-right">{staticContent.PACKAGE_LIST_PAGE_PACKAGE_EDIT_BUTTON_TEXT}</Button>
                                                    }
                                                    no={
                                                        <Button disabled={true} onClick={() => this.handleOpenEditPackageOverlay(item)}
                                                        style={{width: '64px', backgroundColor: '#2A52BB'}}
                                                        className="btn btn-primary pull-right">{staticContent.PACKAGE_LIST_PAGE_PACKAGE_EDIT_BUTTON_TEXT}</Button>
                                                    }
                                                />

                                            </CardFooter>
                                        </CustomCard>
                                    )
                                })
                            }
                        </div> : null
                    // <div className="empty-list-illustrations">
                    //     <VmSvgImage
                    //         targetClass=""
                    //         iconName="vm-package-illustration"
                    //         altText="No Package Illustration"
                    //     />
                    //     <Button onClick={() => this.handleOpenSelectPackageOverlay()} style={{width: '200px'}}
                    //             className="btn btn-primary pull-right">{staticContent.PACKAGE_LIST_PAGE_ADD_PACKAGE_BUTTON_TEXT}</Button>
                    //     {/*<div style={{color:'#737373',marginTop:'20px'}}>No Packages Available</div>*/}
                    // </div>
                }
            </div>
        </div>;


        return (
            <div className="row lead-manage-page"  style={{padding: this.props.windowWidth > breakpoint ? '10px 30px' : '10px', marginTop: '0px'}}>
                <Helmet>
                    <title>Package List</title>
                </Helmet>
                <Drawer placement={this.props.windowWidth > breakpoint ? 'right' : 'bottom'} open={this.state.isShowEditPackage || this.state.isShowAddPackage} onClose={() => {
                    this.handleCloseEditPackageOverlay()
                    this.handleCloseAddPackageOverlay()
                }} width={'50vw'}
                        height={this.props.windowWidth < breakpoint && '70vh'}
                        title={this.state.isShowEditPackage ? 'Edit Package' : this.state.isShowAddPackage ? 'Add Package' : ''}>
                    {drawerContent}
                </Drawer>
                    {/* <Breadcrumb
                        isLeadManageFromLeadList={this?.props?.isLeadManageFromLeadList ? this.props.isLeadManageFromLeadList : ''}
                        contact_name={this?.props?.lead?.contact_name ? this.props.lead.contact_name : '--'}
                    /> */}
                    {/*<Button onClick={() => this.props.dispatch(clearPackageCache(this.props.venueid ? this.props.venueid : ''))}*/}
                    {/*        style={{width: '200px', height: '40px', position: 'relative', right: this.props.windowWidth > breakpoint ? '105px' : '115px', top: '6px', backgroundColor: '#2A52BB'}}*/}
                    {/*        className="btn btn-primary pull-right">{staticContent.PACKAGE_LIST_PAGE_CLEAR_CACHE ? staticContent.PACKAGE_LIST_PAGE_CLEAR_CACHE : 'Clear Cache'}*/}
                    {/*</Button>*/}

          {/* {this.props.isFullViewTabvisible && (
                 <div>
                {this.props.windowWidth > breakpoint ? <NavBar
                    url={`/event/${this.props.leadid || this.props.params?.leadid || this.props?.leadId}/package/list`}
                    leadid={this.props.leadid || this.props.params?.leadid}
                    staticContent={staticContent}
                /> : <MobileNavigationBar
                    url={`/event/${this.props.leadid || this.props.params?.leadid || this.props?.leadId}/package/list`}
                    leadid={this.props.leadid || this.props.params?.leadid}
                    staticContent={staticContent}
                />}
                </div> )} */}
                <div  className={this.props.isFullViewTabvisible ? "lead-manage-body-wrapper" : ""} style={{border: (this.props.windowWidth < breakpoint) && 'none', height: '80vh', overflow: 'auto'}}>
                    {
                        this.props.isFetchingSubPackage ?
                            <div style={{
                                minHeight: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <BasicLoader size={14}/>
                            </div>
                            :
                            segment
                    }
                </div>
                {/*{*/}
                {/*  this.state.isShowEditPackage ?*/}
                {/*      <CenterBasicOverlay*/}
                {/*          isOverlayOpen={this.state.isShowEditPackage}*/}
                {/*          closeOverlay={this.handleCloseEditPackageOverlay}*/}
                {/*          width={1000}*/}
                {/*          overlayTitle={this.state.editPackage.name}*/}
                {/*      >*/}
                {/*        <EditPackage*/}
                {/*            showMessage={this.showMessage}*/}
                {/*            package={{...this.state.editPackage}}*/}
                {/*            staticContent={staticContent}*/}
                {/*            isUpdatingSubPackage={this.props.isUpdatingSubPackage}*/}
                {/*            updatePackage={this.handleUpdatePackage}*/}
                {/*            menu={this.state.editPackage && !_.isEmpty(this.state.editPackage) && this.state.editPackage.menuItems ? this.state.editPackage.menuItems : this.props.menuList}*/}
                {/*        />*/}
                {/*      </CenterBasicOverlay>*/}
                {/*      :*/}
                {/*      null*/}
                {/*}*/}
                {/*{*/}
                {/*    this.state.isShowAddPackage ?*/}
                {/*        <CenterBasicOverlay*/}
                {/*            isOverlayOpen={this.state.isShowAddPackage}*/}
                {/*            closeOverlay={this.handleCloseAddPackageOverlay}*/}
                {/*            isShowBackButton={true}*/}
                {/*            handleBack={this.handleOpenSelectPackageOverlay}*/}
                {/*            overlayTitle={this.state.addPackage.name ? this.state.addPackage.name : 'Custom Package'}*/}
                {/*            width={1000}*/}
                {/*        >*/}
                {/*            <AddPackage*/}
                {/*                showMessage={this.showMessage}*/}
                {/*                staticContent={staticContent}*/}
                {/*                package={this.state.addPackage}*/}
                {/*                eventId={this.props.leadid || this.props.params.leadid}*/}
                {/*                isAddingSubPackage={this.props.isAddingSubPackage}*/}
                {/*                addPackage={this.handleAddNewSubPackage}*/}
                {/*                menu={this.state.addPackage && !_.isEmpty(this.state.addPackage) && this.state.addPackage.menuItems ? this.state.addPackage.menuItems : this.props.menuList}*/}
                {/*            />*/}
                {/*        </CenterBasicOverlay>*/}
                {/*        :*/}
                {/*        null*/}
                {/*}*/}
                {/*{*/}
                {/*  this.state.isShowSelectPackage ?*/}
                {/*      <CenterBasicOverlay*/}
                {/*          isOverlayOpen={this.state.isShowSelectPackage}*/}
                {/*          closeOverlay={this.handleCloseSelectPackageOverlay}*/}
                {/*          overlayTitle={'Select Package'}*/}
                {/*          width={1000}*/}
                {/*      >*/}
                {/*        <div style={{*/}
                {/*          backgroundColor: '#fff',*/}
                {/*          display: 'flex',*/}
                {/*          justifyContent: 'space-between',*/}
                {/*          flexWrap: 'wrap'*/}
                {/*        }}>*/}
                {/*          {*/}
                {/*            this.props.venuePackageList.map(item => (*/}
                {/*                <div key={item._id} style={{*/}
                {/*                  width: '48%',*/}
                {/*                  position: 'relative',*/}
                {/*                  cursor: 'pointer',*/}
                {/*                  fontSize: '14px',*/}
                {/*                  display: 'block',*/}
                {/*                  color: '#7B7B7B',*/}
                {/*                  borderRadius: '5px',*/}
                {/*                  border: '1px solid #CDDEFF',*/}
                {/*                  marginTop: '8px',*/}
                {/*                  padding: '10px 15px'*/}
                {/*                }} onClick={() => this.handleSelectAddPackage(item)}>{item.name}</div>*/}
                {/*            ))*/}
                {/*          }*/}
                {/*          <div key={'custom'} style={{*/}
                {/*            width: '48%',*/}
                {/*            position: 'relative',*/}
                {/*            cursor: 'pointer',*/}
                {/*            fontSize: '14px',*/}
                {/*            display: 'block',*/}
                {/*            color: '#7B7B7B',*/}
                {/*            borderRadius: '5px',*/}
                {/*            border: '1px solid #CDDEFF',*/}
                {/*            marginTop: '8px',*/}
                {/*            padding: '10px 15px'*/}
                {/*          }} onClick={() => this.handleSelectAddPackage({})}>{`Custom Package`}</div>*/}
                {/*        </div>*/}
                {/*      </CenterBasicOverlay>*/}
                {/*      :*/}
                {/*      null*/}
                {/*}*/}
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
     leadid: props.params?.leadid,
        leadId: getSelectedLeadId(state),
        venueid: getActiveVenueId(state),
        currentUrl: props.location?.pathname,
        staticContent: getLeadManageStaticContent(state),
        pageSize: getSubPackagePageSize(state),
        pageNum: getSubPackagePageNum(state),
        count: getSubPackageCount(state),
        packageList: getSubPackages(state),
        menuList: getMenuList(state),
        lead: getLeadDetails(state),
        logId: getLeadLogId(state),    // todo raghav
        agent: getUserAgent(state),           // todo raghav
        userId: getSessionUserId(state),
        isAddingSubPackage: getAddingSubPackageStatus(state),
        isUpdatingSubPackage: getEditingSubPackageStatus(state),
        isFetchingSubPackage: getFetchingSubpackageListStatus(state),
        isLeadManageFromLeadList: getLeadManageParentPath(state),
        packages: getPackages(state),
        partneraccount: getPartnerAccount(state),
        isFullViewTabvisible: getFullViewTabVisiblestate(state)

    };
}


PackageListPage.propTypes = {
    // dispatch: PropTypes.func.isRequired,
    router: PropTypes.object,

};

export default connect(mapStateToProps)(withRoute(PackageListPage));
